import { MenuEntry } from '@mozartfinance/uikit'

const config: MenuEntry[] = [

  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  

  
]

export default config
